




import {Component} from 'vue-property-decorator';
import BaseNavigationPage from '@/components/BaseNavigationPage.vue';
import {applicationStore} from '@/store/modules/application';
import {mixins} from 'vue-class-component';
import Navigation from '@/mixins/Navigation';
import {chatStore} from "@/store/modules/chat/ChatStore";
import {profileStore} from "@/store/modules/profile";

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate'])

@Component({
  name: 'business',
  components: {BaseNavigationPage}
})
export default class Business extends mixins(Navigation) {
  USERS = 'users'
  USER_INFO = 'user-info'
  ORGANISATION = 'organization'
  PARTNERSHIP = 'partnerships'
  COMPANY_PROFILE = 'company-profile'
  CUSTOMER_MANAGEMENT = 'customer-management'
  
  get business() {
    return applicationStore.business;
  }

  get navItems() {
    const {USERS, USER_INFO, ORGANISATION, PARTNERSHIP, COMPANY_PROFILE, CUSTOMER_MANAGEMENT} = this
    const name = this.$route.name || ''
    return [
      {
        title: 'Company profile',
        type: COMPANY_PROFILE,
        name: COMPANY_PROFILE,
        query: {},
        params: {},
        selected: name === COMPANY_PROFILE
      },
      {
        title: 'User management',
        type: USERS,
        name: USERS,
        query: {tab: 0},
        params: {},
        selected: [USERS, USER_INFO].includes(name)
      },
      {
        title: 'Organization',
        type: ORGANISATION,
        name: ORGANISATION,
        query: {},
        params: {},
        selected: name === ORGANISATION
      },
      {
        title: 'Partnerships',
        type: PARTNERSHIP,
        name: PARTNERSHIP,
        query: {},
        params: {},
        selected: name === PARTNERSHIP
      },
      {
        title: 'Customer management',
        type: CUSTOMER_MANAGEMENT,
        name: CUSTOMER_MANAGEMENT,
        query: {},
        params: {},
        selected: name === CUSTOMER_MANAGEMENT
      }
    ];
  }

  onNavItemSelected(navItem) {
    this.$router.push({name: navItem.name, params: navItem.params, query: navItem.query});
  }

  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (to.name === 'user-info') {
        if (!profileStore.t2bUser.admin) {
          return await vm.$router.push({ name: 'access-denied'})
        }
      }
    })
  }

  async beforeRouteUpdate(to, from, next) {
    if (to.name === 'user-info') {
      if (!profileStore.t2bUser.admin && !profileStore.t2bUser.superAdmin) {
        next({ name: 'access-denied'})
      } else {
        next()
      }
    } else {
      next()
    }
  }

  created() {
    console.log('business created')
    this.selectPage(6);
  }
}
